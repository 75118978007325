import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (items && items.length > 0 && searchText) {
      return items.filter(item => {
        if (item.description && item.description.toLowerCase().search(searchText.toLowerCase()) > -1)
          return true;
        if(item.title && item.title.toLowerCase().search(searchText.toLowerCase()) > -1)
          return true;
        if(item.name && item.name.toLowerCase().search(searchText.toLowerCase()) > -1)
          return true;
        return false;
      })
    }
    else {
      return items;
    }
  }
}
