import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ItemAutocomplete } from './ItemAutocomplete';
import { IonSearchbar } from '@ionic/angular';

@Component({
  selector: 'app-autocomplete',
  templateUrl: 'autocomplete.component.html',
})
export class AutocompleteComponent implements OnInit, AfterViewInit {

  @Input() items: ItemAutocomplete[] = [];

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionItem = new EventEmitter<string>();

  @ViewChild('search', { static: false }) searchElement: IonSearchbar;

  private filteredItems: ItemAutocomplete[] = [];
  private searchValue = '';

  ngOnInit() {
    this.filteredItems = [...this.items];

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchElement) {
        this.searchElement.setFocus();
      }
    }, 500);

  }

  searchbar(ev) {
    this.filterList(ev.target.value);
  }

  filterList(searchQuery: string | undefined) {

    if (searchQuery === undefined) {
      this.filteredItems = [...this.items];
    } else {

      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) => item.text.toLowerCase().includes(normalizedQuery));

    }
  }

  trackItems(index: number, item: ItemAutocomplete) {
    return item.value;
  }

  select(item: ItemAutocomplete) {
    this.searchValue = item.text;
  }

  cancel() {
    this.selectionCancel.emit();
  }

  confirm() {
    if (this.searchValue === '') {
      this.cancel();
    }

    this.selectionItem.emit(this.searchValue);
  }
}
