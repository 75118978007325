import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from 'src/app/tools/pipes/filter.pipe';

// Modulo que contiene los componentes que se comparten entre varios modulos
// esto evita problema con las notificaciones de componentes duplicados

@NgModule({
  imports: [
  ],
  declarations: [
    FilterPipe
  ],
  exports: [
    FilterPipe
  ]
})

export class SharedModule { }
